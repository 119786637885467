<script setup lang="ts">
import {
  VBadge,
  VButton,
  VIcon,
  VLink,
  VTable,
  VModal,
  VSection,
  VTextField,
  VTextArea,
} from '@/modules/shared/components'
import { capitalize, get } from 'lodash'
import { rails_url } from '@/modules/shared/utils/rails'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useModal } from '@/modules/shared/composables/use-modal'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useInvoiceStore } from '../stores/invoice-store'

const { t } = useI18n()
const route = useRoute()
const props = defineProps<{
  invoices: any[]
  investable_id: string
  investable_type: string
  skeleton: boolean
  enable_multiple_payment?: boolean
  hidden_columns?: string[]
  isAdmin?: boolean
}>()
const invoiceStore = useInvoiceStore()
const selectedInvoiceType = ref('all')
const selectedInvoices = ref<number[]>([])
const invoicePaymentFormModal = useModal()
const loading = ref(false)

const hidden_columns = computed(() => props.hidden_columns || [])
const toggleAllInvoice = (e) => {
  if (e.target.checked) {
    selectedInvoices.value = current_invoices.value.map((invoice) => invoice.id)
  } else {
    selectedInvoices.value = []
  }
}
const toggleInvoiceSelection = (id: number) => {
  if (selectedInvoices.value.includes(id)) {
    selectedInvoices.value = selectedInvoices.value.filter((invoiceId) => invoiceId !== id)
  } else {
    selectedInvoices.value.push(id)
  }
}
const selectInvoiceType = (type: string) => {
  selectedInvoiceType.value = type
}

const current_invoices = computed(() =>
  props.invoices.filter((invoice) => {
    if (selectedInvoiceType.value === 'all') {
      return true
    }

    if (selectedInvoiceType.value === 'outstanding') {
      return !invoice.marked_as_paid
    }

    if (selectedInvoiceType.value === 'paid') {
      return invoice.marked_as_paid
    }
  }),
)
const outstanding_invoice_ids = computed(() =>
  props.invoices.filter((invoice) => !invoice.marked_as_paid).map((invoice) => invoice.id),
)
const paid_invoice_ids = computed(() =>
  props.invoices.filter((invoice) => invoice.marked_as_paid).map((invoice) => invoice.id),
)

const initialState = {
  invoice_ids: [],
  paid_at: null,
  instructions: null,
}
const rules = {
  invoice_ids: { required },
  paid_at: { required },
}
const invoicePaymentForm = ref({ ...initialState })
const v$ = useVuelidate(rules, invoicePaymentForm, { $lazy: true })

const openInvoicePaymentForm = () => {
  const invoice_ids = selectedInvoices.value.filter((invoice_id) => outstanding_invoice_ids.value.includes(invoice_id))
  if (invoice_ids.length === 0) return alert('Please select at least one outstanding invoice to mark as paid')
  invoicePaymentFormModal.open()
}

const submitInvoicePaymentForm = async () => {
  invoicePaymentForm.value.invoice_ids = selectedInvoices.value.filter((invoice_id) =>
    outstanding_invoice_ids.value.includes(invoice_id),
  )
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true
  await invoiceStore.markMultipleInvoicesAsPaid(invoicePaymentForm.value)
  await invoiceStore.fetchInvoices(props.investable_type, props.investable_id)
  loading.value = false
  invoicePaymentFormModal.close()

  // reset
  invoicePaymentForm.value = { ...initialState }
  selectedInvoices.value = []
}

const markInvoicesAsUnpaid = async () => {
  const invoice_ids = selectedInvoices.value.filter((invoice_id) => paid_invoice_ids.value.includes(invoice_id))
  if (invoice_ids.length === 0) return alert('Please select at least one paid invoice to mark as unpaid')

  const confirm = window.confirm('Are you sure you want to mark the selected invoices as unpaid?')
  if (!confirm) return

  loading.value = true
  await invoiceStore.markMultipleInvoicesAsUnpaid(invoice_ids)
  await invoiceStore.fetchInvoices(props.investable_type, props.investable_id)
  loading.value = false

  // reset
  selectedInvoices.value = []
}
</script>

<template>
  <div class="mb-3 flex items-center justify-between">
    <div class="flex items-center space-x-1.5">
      <VButton :active="selectedInvoiceType === 'all'" class="w-32" @click="selectInvoiceType('all')">
        <span>{{ capitalize(t('shared.all')) }}</span>
      </VButton>
      <VButton :active="selectedInvoiceType === 'outstanding'" class="w-32" @click="selectInvoiceType('outstanding')">
        <span>{{ capitalize(t('shared.outstanding')) }}</span>
      </VButton>
      <VButton :active="selectedInvoiceType === 'paid'" class="w-32" @click="selectInvoiceType('paid')">
        <span>{{ capitalize(t('shared.paid')) }}</span>
      </VButton>
    </div>
    <div class="flex items-center space-x-1.5" v-if="isAdmin">
      <VButton size="sm" variant="v-blue" :loading="loading" :click="markInvoicesAsUnpaid">
        {{ capitalize(t('shared.mark as unpaid')) }}
      </VButton>
      <VButton size="sm" variant="v-blue" :disabled="loading" :click="openInvoicePaymentForm">
        {{ capitalize(t('shared.mark as paid')) }}
      </VButton>
    </div>
  </div>
  <VTable
    :columns="[
      {
        key: 'checkbox',
        name: '',
        type: 'string',
        align: 'left',
        is_visible: true,
        is_accessible: props.enable_multiple_payment,
        enable_thead_slot: true,
      },
      {
        key: 'name',
        name: capitalize(t('shared.name')),
        type: 'string',
        align: 'left',
        fixed: true,
        is_visible: !hidden_columns.includes('name'),
      },
      {
        key: 'investor',
        name: capitalize(t('shared.investor')),
        type: 'string',
        align: 'left',
        is_visible: !hidden_columns.includes('investor'),
      },
      {
        key: 'date',
        name: capitalize(t('shared.due date')),
        type: 'date',
        sorted: true,
        align: 'left',
        is_visible: !hidden_columns.includes('date'),
      },
      {
        key: 'status',
        name: capitalize(t('shared.status')),
        type: 'string',
        align: 'left',
        is_visible: !hidden_columns.includes('status'),
      },
      {
        key: 'amount',
        name: capitalize(t('shared.total amount due')),
        type: 'currency',
        align: 'right',
        is_visible: !hidden_columns.includes('amount'),
        aggregate: 'sum',
      },
    ]"
    :items="current_invoices"
    :name="`${investable_type}-${investable_id}-invoices`"
    :skeleton="skeleton"
    :slots="['investor', 'invoice_number', 'name', 'status', 'checkbox']"
  >
    <template #thead.checkbox="{ column }">
      <input type="checkbox" @change="toggleAllInvoice" class="cursor-pointer" />
    </template>
    <template #checkbox="{ item }">
      <input type="checkbox" :checked="selectedInvoices.includes(item.id)" @change="toggleInvoiceSelection(item.id)" />
    </template>
    <template #investor="{ item }">
      <RouterLink
        class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
        :to="
          get(item, 'investor.ruby_type') === 'fundingentity'
            ? { name: 'investing.other-entity.overview', params: { other_entity_id: get(item, 'investor.id') } }
            : { name: 'investing.individual-overview', params: { individual_id: get(item, 'investor.id') } }
        "
      >
        {{ get(item, 'investor.name') }}
      </RouterLink>
    </template>
    <template #invoice_number="{ item }">
      <a class="hyperlink" :href="`${rails_url()}/invoices/${get(item, 'id')}`"> #{{ get(item, 'id') }} </a>
    </template>
    <template #name="{ item }">
      <a class="hyperlink" :href="`${rails_url()}/invoices/${get(item, 'id')}`">
        {{ get(item, 'name') }}
      </a>
    </template>
    <template #status="{ item }">
      {{ item.marked_as_paid ? 'paid' : 'outstanding' }}
    </template>
  </VTable>
  <VModal :modalStore="invoicePaymentFormModal">
    <template #main>
      <VSection label="Mark multiple invoices as paid">
        <form @submit.prevent="submitInvoicePaymentForm">
          <div class="mt-6 space-y-3">
            <VTextField
              v-model="invoicePaymentForm.paid_at"
              :label="capitalize(t('shared.payment date'))"
              property="paid_at"
              ref="autofocus"
              type="date"
              :v$="v$"
            />
            <VTextArea
              :label="capitalize(t('shared.Intructions / Notes'))"
              v-model="invoicePaymentForm.instructions"
              :v$="v$"
              property="instructions"
            />
          </div>
        </form>
      </VSection>
    </template>
    <template #footer>
      <div class="flex items-center justify-between space-x-3">
        <VButton :click="invoicePaymentFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
        <VButton :click="submitInvoicePaymentForm" class="w-full" size="lg" :loading="loading" variant="primary">
          {{ capitalize(t('shared.submit')) }}
        </VButton>
      </div>
    </template>
  </VModal>
</template>
