<script setup lang="ts">
import { endOfDay } from 'date-fns'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { VButton, VIcon } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { locateData } from '@/modules/shared/utils/data'
import { rails_url } from '@/modules/shared/utils/rails'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { useDistributionStore } from '../stores/distribution-store'
import { useEntityStore } from '../stores/entity-store'
import { usePortfolioDistributionStore } from '../stores/portfolio-distribution-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import DistributionsList from '../components/distributions-list.vue'
import { getChartData, transformEntity } from '../utils/entity'

const route = useRoute()

///////////////////////////////////////////////////////////////////////////////

const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const vehicleStore = useInvestingVehicleStore()
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')

///////////////////////////////////////////////////////////////////////////////
// Misc
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const currentEntity = ref(null)
const investorStore = useInvestorStore()
const distributionStore = useDistributionStore()
const portfolioDistributionStore = usePortfolioDistributionStore()

const filteredTransactions = computed(() => {
  return distributionStore.distributions.filter((t) => investorStore.state.selectedKeys.includes(t.investor._cid))
})

const chartData = computed(() => {
  const entities = currentEntity.value
    ? transformEntity([currentEntity.value] || [], cid.value, true, investorStore.state.selectedKeys)
    : []
  return getChartData(entities, true, investorStore.state.selectedKeys)
})

const skeleton = ref(true)

onMounted(async () => {
  await entityStore.fetchEntity({
    type: route.params.entity_type as string,
    id: route.params.entity_id as string,
  })

  await investorStore.reset()
  // prettier-ignore
  await Promise.all([
    vehicleStore.fetchVehicles(),
    distributionStore.fetchDistributions(route.params.entity_type, route.params.entity_id),
    investorStore.listProfiles(),
    portfolioDistributionStore.fetchPortfolioDistributions(),
  ])

  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <DistributionsList
      :distributions="filteredTransactions"
      :currency="currentEntity?.currency || 'USD'"
      :disabledColumns="['entity.name']"
      :investable_id="route.params.entity_id"
      :investable_type="route.params.entity_type"
      :isAdmin="isAdmin"
      :portfolio_distributions="portfolioDistributionStore.portfolio_distributions"
      :skeleton="skeleton"
      :chartData="chartData"
    >
      <template #actions>
        <RouterLink :to="{ name: 'investing.disbursement.new' }" v-if="isAdmin">
          <VButton size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>Distribution</div>
            </div>
          </VButton>
        </RouterLink>
      </template>
    </DistributionsList>
  </TheLayout>
</template>
