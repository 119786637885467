<script setup lang="ts">
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useExtendedI18n } from '@/i18n'
import { VSection, VTextField, VTextArea, VButton } from '@/modules/shared/components'
import { scrollToError } from '@/modules/shared/utils/form'
import { toNumber, toMoney, initialMoney } from '@/modules/shared/utils/money'
import { CapitalCall } from '../stores/capital-call-store'
import { useCapitalCall } from '../utils/use-capital-call-form'

///////////////////////////////////////////////////////////////////////////////
// Setup
///////////////////////////////////////////////////////////////////////////////

const { n, t } = useExtendedI18n()
const route = useRoute()

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  capital_call: CapitalCall
}>()

const emit = defineEmits<{
  (e: 'submit', payload: any): void
}>()

///////////////////////////////////////////////////////////////////////////////

const isNewRecord = computed(() => !props.capital_call.id)
const isPersisted = computed(() => !isNewRecord.value)

const totalCapitalToAllocate = computed(() => {
  if (props.capital_call.selected_capital_type === '$' && props.capital_call.entered_capital_amount) {
    return toMoney(props.capital_call.entered_capital_amount, props.capital_call.currency)
  }

  return null
})

const initializeQuestions = () => {
  const props_config = props.capital_call.config
  isNewCapitalCall.value = props_config.is_new_capital_call
  whenShouldNotificationBeSent.value = props_config.when_should_notification_be_sent
  includeCapital.value = props_config.include_capital
  allocateManagementFees.value = props_config.allocate_management_fees
  selectedTimePeriod.value = props_config.selected_time_period
  selectedCapitalType.value = props_config.selected_capital_type
  selectedManagementFeesType.value = props_config.selected_management_fees_type
}
const initializeMacros = () => {
  macros.value.total_capital = props.capital_call.entered_capital_amount
  macros.value.management_fees = props.capital_call.entered_management_fees_amount
  macros.value.other_fees = props.capital_call.entered_other_fees_amount
}
const initializeCapitalCall = () => {
  return {
    ...props.capital_call,
    date: props.capital_call.date ? format(new Date(props.capital_call.date), 'yyyy-MM-dd') : null,
    schedule_date: props.capital_call.schedule_date
      ? format(new Date(props.capital_call.schedule_date), 'yyyy-MM-dd')
      : null,
    capital_call_transfers: props.capital_call.capital_call_transfers
      .map((transfer) => capitalCallUtil.transformTransfer(transfer, props.capital_call))
      .sort((a, b) => a.name.localeCompare(b.name)),
  }
}

const resetMacro = (key) => {
  macros.value[key] = null
  macroUpdateTransfer()
}

///////////////////////////////////////////////////////////////////////////////

// initial state
const isNewCapitalCall = ref('no') // this is "prior": 'no' -> 'true', 'yes' -> 'false'
const whenShouldNotificationBeSent = ref('now') // this is if scheduled_date is present: 'now' -> null, 'later' -> scheduled_date present
const includeCapital = ref('1') // 1: Call capital and fees, 2: Only call capital, 3: Only call fees
const allocateManagementFees = ref('1') // 1: Use the default fee % as shown below, 2: Enter a fee to apply to all investors
const selectedTimePeriod = ref('1') // 1: Annually, 2: Semi-annually, 3: Quarterly, 4: Monthly
const selectedCapitalType = ref('$')
const selectedManagementFeesType = ref('%')
const macros = ref({
  total_capital: null,
  management_fees: null,
  other_fees: null,
})
// these values are initialized first because it is needed to display in the table as temporary
const capitalCall: any = ref({
  prior: 'false',
  date: null,
  schedule_date: null,
  wiring_instructions: null,
  notes: null,
  capital_call_transfers: [],
})

const config = computed(() => {
  return {
    isNewCapitalCall: isNewCapitalCall.value,
    whenShouldNotificationBeSent: whenShouldNotificationBeSent.value,
    includeCapital: includeCapital.value,
    allocateManagementFees: allocateManagementFees.value,
    selectedTimePeriod: selectedTimePeriod.value,
    selectedCapitalType: selectedCapitalType.value,
    selectedManagementFeesType: selectedManagementFeesType.value,
  }
})
const capitalCallUtil = useCapitalCall(config)

///////////////////////////////////////////////////////////////////////////////
// Investor selection
///////////////////////////////////////////////////////////////////////////////

const toggleAllInvestor = (checked) => {
  if (isNewRecord.value) {
    capitalCall.value.capital_call_transfers.forEach((transfer) => {
      transfer.is_active = checked
    })
  }
}

const toggleInvestor = (e, transfer) => {
  transfer.is_active = e.target.checked
  if (transfer.is_active) {
    capitalCallUtil.updateMacroTransfer(transfer, macros.value)
  } else {
    capitalCallUtil.resetTransfer(transfer)
  }
}

///////////////////////////////////////////////////////////////////////////////

// FORM DATA
const rules = {
  prior: { required },
  date: { required },
}
const v$ = useVuelidate(rules, capitalCall, { $lazy: true })
const updateTransfer = (transfer) => {
  transfer = capitalCallUtil.updateTransfer(transfer)
}
const macroUpdateTransfer = () => {
  capitalCall.value.capital_call_transfers
    .filter((transfer) => transfer.is_active)
    .forEach((transfer) => {
      transfer = capitalCallUtil.updateMacroTransfer(transfer, macros.value)
    })
}
const aggregateValues = computed(() => capitalCallUtil.calculateAggregate(capitalCall.value.capital_call_transfers))

const getManagementFee = (transfer) => {
  if (macros.value.management_fees && selectedManagementFeesType.value === '%')
    return macros.value.management_fees / 100
  return transfer.management_fee_percentage || 0
}

const submit = async () => {
  const valid = await v$.value.$validate()
  if (!valid) {
    scrollToError(v$, Object.keys(rules))
    return
  }
  let entered_capital_amount = null
  let entered_management_fees_amount = null
  let entered_other_fees_amount = null
  if (selectedCapitalType.value === '$') {
    if (!!macros.value.total_capital) entered_capital_amount = toNumber(macros.value.total_capital)
  } else {
    entered_capital_amount = macros.value.total_capital
  }
  if (selectedManagementFeesType.value === '$') {
    if (!!macros.value.management_fees) entered_management_fees_amount = toNumber(macros.value.management_fees)
  } else {
    entered_management_fees_amount = macros.value.management_fees
  }
  if (!!macros.value.other_fees) entered_other_fees_amount = toNumber(macros.value.other_fees)

  const payload = {
    id: capitalCall.value.id,
    investor_set_id: route.params.entity_id,
    capital_amount: toNumber(aggregateValues.value.capital),
    currency: capitalCall.value.currency,
    date: capitalCall.value.date,
    schedule_date: capitalCall.value.schedule_date,
    notes: capitalCall.value.notes,
    wiring_instructions: capitalCall.value.wiring_instructions,
    prior: isNewCapitalCall.value === 'no' ? 'true' : 'false',
    fee_percentage: 0,
    fee_percentage_of_commitment: 'false',
    include_fees: '1',
    itemize_fee: true,
    other_fee_amount: toNumber(aggregateValues.value.other_fees),
    entered_capital_amount: entered_capital_amount,
    entered_management_fees_amount: entered_management_fees_amount,
    entered_other_fees_amount: entered_other_fees_amount,
    include_capital: includeCapital.value,
    allocate_management_fees: allocateManagementFees.value,
    selected_time_period: selectedTimePeriod.value,
    selected_capital_type: selectedCapitalType.value,
    selected_management_fees_type: selectedManagementFeesType.value,
    other_fee_type: 'pro-rata',
    config: config.value,
    capital_call_transfers_attributes: [],
  }

  payload.capital_call_transfers_attributes = capitalCall.value.capital_call_transfers
    .filter((transfer) => transfer.is_active)
    .map((transfer) => {
      return {
        id: transfer.id,
        investor_set_commitment_id: transfer.investor_set_commitment_id,
        capital_amount: toNumber(transfer.capital || initialMoney),
        currency: capitalCall.value.currency,
        date: null,
        fee_amount: toNumber(transfer.management_fees || initialMoney),
        other_fee_amount: toNumber(transfer.other_fees || initialMoney),
        prior: false,
      }
    })
  emit('submit', payload) // Emit the 'submit' event with the payload
}
// Pass the submit function back to the parent
defineExpose({
  submit,
})

// TABLE CONFIG
const columns = computed(() => {
  return [
    { key: 'capital', label: 'Capital', is_hidden: !['1', '2'].includes(includeCapital.value) },
    { key: 'management_fees', label: 'Management Fees', is_hidden: !['1', '3'].includes(includeCapital.value) },
    { key: 'other_fees', label: 'Other Fees', is_hidden: !['1', '3'].includes(includeCapital.value) },
    { key: 'total_called', label: 'Total Called', disabled: true },
    { key: 'commitment_remaining', label: 'Commitment Remaining', disabled: true },
  ]
})

onMounted(() => {
  initializeQuestions()
  capitalCall.value = initializeCapitalCall()
  initializeMacros()

  watch(
    () => selectedCapitalType.value,
    () => {
      macros.value.total_capital = null
      macroUpdateTransfer()
    },
  )
  watch(
    () => selectedManagementFeesType.value,
    () => {
      macros.value.management_fees = null
      macroUpdateTransfer()
    },
  )
  watch(
    () => includeCapital.value,
    (v) => {
      switch (v) {
        case '1':
        case '2':
          macros.value.management_fees = null
          macros.value.other_fees = null
          macroUpdateTransfer()
          break
        case '3':
          macros.value.total_capital = null
          macroUpdateTransfer()
          break
      }
    },
  )
  watch(
    () => allocateManagementFees.value,
    (v) => {
      if (v === '1') {
        macros.value.management_fees = null
        selectedManagementFeesType.value = '%'
        macroUpdateTransfer()
      }
    },
  )
  watch(
    () => selectedTimePeriod.value,
    (v) => {
      if (v) {
        macroUpdateTransfer()
      }
    },
  )
})
</script>

<template>
  <VSection class="mt-12 space-y-7 px-52" id="investor-allocations">
    <div class="flex gap-3">
      <div class="w-1/2">
        <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
          {{ capitalize(t('shared.general information')) }}
          <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">1/2</span>
        </h3>
      </div>

      <div class="mt-6 w-1/2 space-y-7">
        <VTextField
          v-model="capitalCall.date"
          :label="capitalize(t('shared.due date'))"
          type="date"
          property="date"
          :v$="v$"
        />
        <div class="my-12 h-1 bg-gray-100"></div>

        <div>
          <label for="x1" class="block text-sm font-medium leading-6 text-gray-900"
            >Would you like to send a notification to investors?</label
          >
          <select
            id="x1"
            name="x1"
            v-model="isNewCapitalCall"
            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
          >
            <option value="yes">Yes, send notifications to investors</option>
            <option value="no">No, only record a call and/or assign fees</option>
          </select>
        </div>
        <div class="space-y-7" v-if="isNewCapitalCall === 'yes'">
          <div>
            <label for="x1" class="block text-sm font-medium leading-6 text-gray-900"
              >When should the notification be sent to investors?</label
            >
            <select
              id="x1"
              name="x1"
              v-model="whenShouldNotificationBeSent"
              class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
            >
              <option value="now">Now</option>
              <option value="later">Schedule for later</option>
            </select>
          </div>

          <VTextField
            v-if="whenShouldNotificationBeSent === 'later'"
            v-model="capitalCall.schedule_date"
            label="Schedule date"
            type="date"
            property="schedule_date"
            :v$="v$"
          />
          <VTextArea
            v-model="capitalCall.notes"
            :label="capitalize(t('shared.note', 0))"
            property="notes"
            :v$="v$"
            description="These notes will be included in the email."
          />
          <VTextArea
            v-model="capitalCall.wiring_instructions"
            :label="capitalize(t('shared.wiring instructions'))"
            property="wiring_instructions"
            :v$="v$"
            description="Wiring instructions are sent encrypted. The investor will need to login to access them. "
          />
        </div>

        <div>
          <!-- Step 1: Are you calling capital? -->

          <div class="my-12 h-1 bg-gray-100"></div>

          <!-- Step 1: Are you calling capital? -->
          <div class="mt-6">
            <label for="x1" class="block text-sm font-medium leading-6 text-gray-900"
              >What would you like to include in this transaction?</label
            >
            <select
              id="x1"
              name="x1"
              v-model="includeCapital"
              class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
            >
              <option value="1">Call capital and fees</option>
              <option value="2">Only call capital</option>
              <option value="3">Only call fees</option>
            </select>
          </div>

          <!-- Show only if capital is being called -->
          <div class="mt-6" v-if="['1', '2'].includes(includeCapital)">
            <label for="x1" class="block text-sm font-medium leading-6 text-gray-900"
              >Capital to allocate to investors in the grid below</label
            >
            <VTextField
              v-model="macros.total_capital"
              :type="selectedCapitalType === '$' ? 'currency' : 'percent'"
              :v$="v$"
              :useActionsSlot="true"
              inputClass="pl-16"
              :onInput="macroUpdateTransfer"
              :description="selectedCapitalType === '$' ? '' : `Percentage is being applied to total commitment`"
            >
              <template #actions>
                <div class="flex h-full py-1.5">
                  <kbd
                    class="inline-flex items-center rounded-l border border-gray-300 px-2 font-sans text-sm shadow-sm"
                    :class="[
                      selectedCapitalType === '$' ? ' text-gray-900' : 'cursor-pointer bg-gray-200 text-gray-400',
                    ]"
                    @click="selectedCapitalType = '$'"
                    >$</kbd
                  >
                  <kbd
                    class="-ml-px inline-flex items-center rounded-r border border-gray-300 px-2 font-mono text-sm shadow-sm"
                    :class="[
                      selectedCapitalType === '%' ? ' text-gray-900' : 'cursor-pointer bg-gray-200 text-gray-400',
                    ]"
                    @click="selectedCapitalType = '%'"
                    >%</kbd
                  >
                </div>
              </template>
            </VTextField>
          </div>
          <div v-if="['1', '3'].includes(includeCapital)">
            <div class="mt-6">
              <!-- Step 2: Would you like to include management fees? -->
              <label for="x2" class="block text-sm font-medium leading-6 text-gray-900"
                >How would you like to allocate management fees?</label
              >
              <select
                id="x2"
                name="x2"
                v-model="allocateManagementFees"
                class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
              >
                <option value="1">Use the default fee % as shown below</option>
                <option value="2">Enter a fee to apply to all investors</option>
              </select>
            </div>
            <div class="mt-6" v-if="allocateManagementFees === '2'">
              <label for="x1" class="block text-sm font-medium leading-6 text-gray-900">Management fees</label>
              <VTextField
                v-model="macros.management_fees"
                :type="selectedManagementFeesType === '$' ? 'currency' : 'percent'"
                :v$="v$"
                :useActionsSlot="true"
                inputClass="pl-16"
                :onInput="macroUpdateTransfer"
                :description="
                  selectedManagementFeesType === '$'
                    ? 'Amount allocated based on ownership'
                    : `Percentage is being applied to ${includeCapital === '1' ? 'capital' : 'total commitment'}`
                "
              >
                <template #actions>
                  <div class="flex h-full py-1.5">
                    <kbd
                      class="inline-flex items-center rounded-l border border-gray-300 px-2 font-sans text-sm shadow-sm"
                      :class="[
                        selectedManagementFeesType === '$'
                          ? ' text-gray-900'
                          : 'cursor-pointer bg-gray-200 text-gray-400',
                      ]"
                      @click="selectedManagementFeesType = '$'"
                      >$</kbd
                    >
                    <kbd
                      class="-ml-px inline-flex items-center rounded-r border border-gray-300 px-2 font-mono text-sm shadow-sm"
                      :class="[
                        selectedManagementFeesType === '%'
                          ? ' text-gray-900'
                          : 'cursor-pointer bg-gray-200 text-gray-400',
                      ]"
                      @click="selectedManagementFeesType = '%'"
                      >%</kbd
                    >
                  </div>
                </template>
              </VTextField>
            </div>
            <div class="mt-6" v-if="includeCapital === '3'">
              <label for="x1" class="block text-sm font-medium leading-6 text-gray-900">Select a time period </label>
              <div class="relative">
                <input
                  id="x1"
                  name="x1"
                  type="text"
                  class="mt-1 block w-full rounded-md border border-gray-300 pl-[340px] shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                />
                <div class="absolute inset-y-0 left-0 flex py-1.5 pl-1.5">
                  <kbd
                    class="inline-flex items-center rounded-l border border-gray-300 px-2 font-sans text-sm shadow-sm"
                    :class="[
                      selectedTimePeriod === '1' ? ' text-gray-900' : 'cursor-pointer bg-gray-200 text-gray-400',
                    ]"
                    @click="selectedTimePeriod = '1'"
                    >Annually</kbd
                  >
                  <kbd
                    class="-ml-px inline-flex items-center border border-gray-300 px-2 font-sans text-sm shadow-sm"
                    :class="[
                      selectedTimePeriod === '2' ? ' text-gray-900' : 'cursor-pointer bg-gray-200 text-gray-400',
                    ]"
                    @click="selectedTimePeriod = '2'"
                    >Semi-annually</kbd
                  >
                  <kbd
                    class="-ml-px inline-flex items-center border border-gray-300 px-2 font-sans text-sm shadow-sm"
                    :class="[
                      selectedTimePeriod === '3' ? ' text-gray-900' : 'cursor-pointer bg-gray-200 text-gray-400',
                    ]"
                    @click="selectedTimePeriod = '3'"
                    >Quarterly</kbd
                  >
                  <kbd
                    class="-ml-px inline-flex items-center rounded-r border border-gray-300 px-2 font-sans text-sm shadow-sm"
                    :class="[
                      selectedTimePeriod === '4' ? ' text-gray-900' : 'cursor-pointer bg-gray-200 text-gray-400',
                    ]"
                    @click="selectedTimePeriod = '4'"
                    >Monthly</kbd
                  >
                </div>
              </div>
            </div>
            <div class="mt-6">
              <label for="x1" class="block text-sm font-medium leading-6 text-gray-900">Other fees</label>
              <VTextField
                v-model="macros.other_fees"
                type="currency"
                :v$="v$"
                :useActionsSlot="true"
                :onInput="macroUpdateTransfer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </VSection>
  <VSection class="space-y-7" id="investor-allocations">
    <div class="flex gap-3">
      <div class="w-1/2">
        <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
          {{ capitalize(t('shared.investor allocation')) }}
          <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">2/2</span>
        </h3>
      </div>

      <div class="w-1/2 space-y-7"></div>
    </div>

    <div class="relative w-full overflow-x-auto">
      <table id="capital-call-form-table" class="w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th class="!pl-3">
              <input
                @change="toggleAllInvestor($event.target['checked'])"
                class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
                type="checkbox"
                checked
                :disabled="isPersisted"
              />
            </th>
            <th class="pl-3 text-left">Investor</th>
            <th class="pl-3 text-right" v-for="column in columns" v-show="!column.is_hidden">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ disabledRow: !transfer.is_active }" v-for="(transfer, i) in capitalCall.capital_call_transfers">
            <td class="pl-3">
              <input
                @change="(e) => toggleInvestor(e, transfer)"
                type="checkbox"
                class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
                :disabled="isPersisted"
                :checked="transfer.is_active"
              />
            </td>
            <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-base text-gray-700">
              {{ transfer.name }}
            </td>
            <td
              class="border-r-[1px] border-gray-300 text-base text-gray-700"
              v-for="column in columns"
              v-show="!column.is_hidden"
            >
              <VTextField
                v-model="transfer[column.key]"
                :disabled="!transfer.is_active || column.disabled"
                type="currency"
                :onInput="() => updateTransfer(transfer)"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right"
                v-if="column.key !== 'management_fees'"
              />
              <div class="flex items-center pl-3" v-else>
                <div class="rounded bg-blue-50 p-1 py-0.5 text-xs text-gray-500">
                  {{ n(getManagementFee(transfer), 'percent') }}
                </div>
                <VTextField
                  v-model="transfer.management_fees"
                  :disabled="!transfer.is_active"
                  type="currency"
                  :onInput="() => updateTransfer(transfer)"
                  inputClass="!border-none !shadow-none !mt-0 text-sm text-right"
                  class="flex-grow"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="pl-3"></td>
            <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-xs text-gray-700"></td>
            <td
              class="border-r-[1px] border-gray-300 bg-gray-50 text-base text-gray-700"
              v-for="column in columns"
              v-show="!column.is_hidden"
            >
              <VTextField
                v-model="aggregateValues[column.key]"
                :disabled="true"
                type="currency"
                inputClass="!border-none !shadow-none !mt-0 text-sm text-right"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </VSection>
</template>

<style>
table#capital-call-form-table {
  @apply text-sm;
}
table#capital-call-form-table thead tr th {
  @apply min-w-[150px] whitespace-nowrap border-b-[1px]  border-gray-300 px-2 py-2 font-semibold;
}
table#capital-call-form-table thead tr th:first-child {
  @apply w-[1%] !min-w-[1%];
}

table#capital-call-form-table tbody tr td {
  @apply border-b-[1px] border-gray-300;
}

table#capital-call-form-table thead tr th:nth-child(1),
table#capital-call-form-table tbody tr td:nth-child(1),
table#capital-call-form-table tfoot tr td:nth-child(1) {
  @apply sticky left-0 bg-white;
}
table#capital-call-form-table thead tr th:nth-child(2),
table#capital-call-form-table tbody tr td:nth-child(2),
table#capital-call-form-table tfoot tr td:nth-child(2) {
  @apply sticky left-[29px] bg-white;
}
table#capital-call-form-table thead tr th:nth-child(2) {
  @apply !min-w-[300px];
}

table#capital-call-form-table tbody tr.disabledRow td {
  @apply bg-gray-100;
}
</style>
