<script setup lang="ts">
import { computed, ref } from 'vue'
import { VIcon } from '@/modules/shared/components'

type Option = {
  label: string
  value: string | boolean
}

const props = withDefaults(
  defineProps<{
    defaultOption?: string | boolean
    description?: string
    id?: string
    info?: string
    label?: string
    modelValue: string | boolean
    options: Option[]
    optionsDirection?: 'horizontal' | 'vertical'
    required?: boolean
    v$?: any
  }>(),
  {
    label: '',
    optionsDirection: 'horizontal',
    required: false,
    v$: {},
  },
)

const emit = defineEmits(['update:modelValue'])

const selectedOption = ref(props.modelValue || props.defaultOption)
const optionsFlow = computed(() => (props.optionsDirection === 'horizontal' ? 'space-x-3' : 'flex-col space-y-2'))

const selectOption = (value: string | boolean) => {
  emit('update:modelValue', value)
}
</script>

<template>
  <fieldset :id="id">
    <label class="flex text-sm font-medium text-gray-700">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
      <div v-if="info" class="group relative ml-3 hover:block">
        <div
          class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:shadow-md hover:ring-gray-700/30 active:shadow-sm"
        >
          <VIcon name="help" class="h-5 w-5 text-gray-700" />
        </div>
        <div class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block">
          <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
            {{ info }}
          </div>
        </div>
      </div>
    </label>
    <p class="mt-1 text-sm text-gray-500" :id="`${id}-description`" v-if="description">{{ description }}</p>
    <div :class="[label ? 'mt-3' : '', 'flex', optionsFlow, 'radio-container']">
      <label v-for="(option, index) in options" :key="index" class="flex gap-2 text-sm">
        <input
          type="radio"
          :value="option.value"
          class="mt-[3px]"
          v-model="selectedOption"
          @click="selectOption(option.value)"
        />
        <div>{{ option.label }}</div>
      </label>
    </div>
  </fieldset>
</template>
