<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { email, required, sameAs } from '@vuelidate/validators'
import { allCountries } from 'country-region-data'
import { capitalize, startCase as _startCase } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { TheHeader, VButton, VIcon, VSection, VTextField } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { rails_url } from '@/modules/shared/utils/rails'
import DisbursementPreferencesForm from '../components/disbursement-preferences-form.vue'
import ProfileImage from '../components/profile-image.vue'
import VSelect from '../components/v-select.vue'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { scrollToSection } from '../utils/scroll-to-section'

function startCase(str: string) {
  return str
    .split(' / ')
    .map((part) => _startCase(part))
    .join(' / ')
}

const NON_US_INVESTOR_TAX_ID_VALUE = 'NON-US INVESTOR'

const route = useRoute()
const router = useRouter()
const { t } = useExtendedI18n()
const authStore = useAuthStore()
const investorStore = useInvestingInvestorStore()
const current_individual = ref(null)
const is_non_us_investor = ref(false)
const isAdmin = computed(
  () =>
    authStore.is_site_or_group_admin || authStore.current_user?.investor_id.toString() === route.params.individual_id,
)

watch(is_non_us_investor, () => {
  if (is_non_us_investor.value) {
    individual_form.value.tax_id = NON_US_INVESTOR_TAX_ID_VALUE
  } else {
    individual_form.value.tax_id = ''
  }
})

const individual_initial_state = {
  birthdate: '',
  city: '',
  country: '',
  disbursement_method: 'wire',
  disbursement_wire_bank_name: '',
  disbursement_wire_bank_address: '',
  disbursement_wire_bank_routing_number: '',
  disbursement_wire_bank_account_number: '',
  disbursement_wire_repeat_bank_account_number: '',
  disbursement_wire_bank_swift_code: '',
  disbursement_wire_account_name: '',
  disbursement_wire_for_further_credit_to: '',
  disbursement_check_receiver_name: '',
  disbursement_other_details: '',
  email: '',
  fincen_id: '',
  first_name: '',
  last_name: '',
  investing_name: '',
  phone: '',
  profile_picture: null,
  replace_profile_picture: false,
  state: '',
  street1: '',
  tax_id: '',
  zip: '',
}

const original_bank_account_number = ref('')
const check_bank_account_number = computed(
  () => original_bank_account_number.value !== individual_form.value.disbursement_wire_bank_account_number,
)

const individual_rules = {
  email: { required, email },
  first_name: { required },
  disbursement_wire_repeat_bank_account_number: {
    sameAsRepeat: sameAs(
      computed(() =>
        check_bank_account_number.value
          ? individual_form.value.disbursement_wire_bank_account_number
          : individual_form.value.disbursement_wire_repeat_bank_account_number,
      ),
    ),
  },
}
const individual_form = ref({ ...individual_initial_state })
const v$ = useVuelidate(individual_rules, individual_form, { $lazy: true })

const countries = computed(() =>
  allCountries.map((country) => {
    // format of allCountries is [countryName, countryCode, countryStates][]
    return {
      label: country[0],
      value: country[1],
    }
  }),
)

const states = computed(() => {
  if (!individual_form.value.country) return []
  const country = allCountries.find((country) => country[1] === individual_form.value.country)
  if (!country) return []

  return country[2].map((state) => {
    // format of state is [name, code][]
    return {
      label: state[0],
      value: state[1],
    }
  })
})

const submit = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  const payload = { ...individual_form.value }

  await investorStore.updateIndividual(payload)

  router.push({ name: 'investing.individual.account' })
}

const removeIndividual = async () => {
  if (!authStore.is_site_or_group_admin) return
  if (!window.confirm('Are you sure?')) return
  await investorStore.removeIndividual(current_individual.value.id)
  router.push({ name: 'investing.individuals' })
}

///////////////////////////////////////////////////////////////////////////////
// Profile Pic
///////////////////////////////////////////////////////////////////////////////

const imageToDisplay = ref(null)

const changeProfileImage = (image: File) => {
  individual_form.value.profile_picture = image
  individual_form.value.replace_profile_picture = true

  // read image to display
  const reader = new FileReader()
  reader.onload = (e) => {
    imageToDisplay.value = e.target.result
  }
  reader.readAsDataURL(image)
}

const removeProfileImage = () => {
  imageToDisplay.value = null
  individual_form.value.profile_picture = null
  individual_form.value.replace_profile_picture = true
}

const toggleIsNonUsInvestor = () => {
  is_non_us_investor.value = !is_non_us_investor.value
}

onMounted(async () => {
  // prevent unauthorize users
  if (!isAdmin.value) {
    router.push({ name: 'dashboard' })
  }
  await scrollToSection(route.hash)
  await Promise.all([investorStore.fetchIndividual(route.params.individual_id as string)])

  current_individual.value = investorStore.items.get(`individual:${route.params.individual_id}`)
  imageToDisplay.value = current_individual.value.profile_picture
  individual_form.value = { ...current_individual.value }
  individual_form.value.disbursement_method = current_individual.value.disbursement_method || 'wire'
  original_bank_account_number.value = current_individual.value.disbursement_wire_bank_account_number
  is_non_us_investor.value = current_individual.value.tax_id === NON_US_INVESTOR_TAX_ID_VALUE
  individual_form.value.street1 = current_individual.value.address.line1
  individual_form.value.city = current_individual.value.address.city
  individual_form.value.state = current_individual.value.address.state
  individual_form.value.zip = current_individual.value.address.postal_code
  individual_form.value.country = current_individual.value.address.country
})
</script>

<template>
  <TheLayout>
    <TheHeader
      title="Edit Profile"
      :back_url_config="{ to: { name: 'investing.individual-overview' }, label: '← Back' }"
    />
    <VSection class="flex gap-3" id="general-information">
      <div class="w-1/2">
        <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
          Personal Information
          <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">1/3</span>
        </h3>
      </div>
      <div class="w-1/2">
        <div class="mb-12 w-fit">
          <ProfileImage
            :profile_picture="imageToDisplay"
            :accept="['.png', '.jpg', '.jpeg']"
            :is_image_new="!current_individual?.profile_picture"
            :initials="current_individual?.initials"
            :onUpload="changeProfileImage"
            :onRemove="removeProfileImage"
          />
        </div>
        <VTextField
          v-model="individual_form.first_name"
          :label="capitalize(t('shared.first name'))"
          property="first_name"
          type="text"
          :v$="v$"
          class="mt-6"
          :required="true"
        />
        <VTextField
          v-model="individual_form.last_name"
          :label="capitalize(t('shared.last name'))"
          property="last_name"
          type="text"
          :v$="v$"
          class="mt-6"
          :required="true"
        />
        <VTextField
          v-if="authStore.is_site_admin"
          v-model="individual_form.investing_name"
          :label="capitalize(t('shared.investing name'))"
          property="investing_name"
          type="text"
          :v$="v$"
          class="mt-6"
          :required="true"
        />
        <hr class="my-12 h-1 border-0 bg-gray-100" />
        <VTextField
          v-model="individual_form.email"
          :label="capitalize(t('shared.email'))"
          property="email"
          type="email"
          :v$="v$"
          class="mt-6"
          :required="true"
        />

        <VTextField
          v-model="individual_form.phone"
          :label="capitalize(t('shared.phone number'))"
          property="phone"
          type="text"
          :v$="v$"
          class="mt-6"
          :required="true"
        />
        <VTextField
          v-model="individual_form.birthdate"
          :label="capitalize(t('shared.birthdate'))"
          property="birthdate"
          type="date"
          :v$="v$"
          class="mt-6"
          :required="true"
        />

        <hr class="my-12 h-1 border-0 bg-gray-100" />

        <VSelect
          v-model="individual_form.country"
          :label="capitalize(t('shared.country'))"
          :options="countries"
          property="country"
          class="mt-6"
          :v$="v$"
          :required="true"
        />

        <VTextField
          v-model="individual_form.street1"
          :label="capitalize(t('shared.street'))"
          property="street1"
          type="text"
          class="mt-6"
          :v$="v$"
          :required="true"
        />

        <VTextField
          v-model="individual_form.city"
          :label="capitalize(t('shared.city'))"
          property="city"
          type="text"
          :v$="v$"
          class="mt-6"
          :required="true"
        />

        <div class="mt-6">
          <VSelect
            v-model="individual_form.state"
            :label="startCase(t('shared.state / province'))"
            :options="states"
            property="state"
            :v$="v$"
            :required="true"
          />
        </div>

        <VTextField
          v-model="individual_form.zip"
          :label="startCase(t('shared.zip / postal code'))"
          property="zip"
          type="text"
          class="mt-6"
          :v$="v$"
          :required="true"
        />
      </div>
    </VSection>
    <VSection class="flex gap-3" id="financial-verification-information">
      <div class="w-1/2">
        <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
          Financial Verification Information
          <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">2/3</span>
        </h3>
      </div>
      <div class="w-1/2">
        <div class="mb-5">
          <VTextField
            v-model="individual_form.tax_id"
            :label="t('shared.SSN / Tax ID')"
            :disabled="is_non_us_investor"
            property="tax_id"
            type="text"
            :v$="v$"
            :required="true"
          />
          <p class="mt-1 text-sm text-gray-500">{{ t('shared.SSN / Tax ID') }} is an encrypted attribute</p>
          <div class="mt-2 flex items-center gap-3">
            <input type="checkbox" v-model="is_non_us_investor" />
            <p class="cursor-pointer text-sm font-medium text-gray-700" @click="toggleIsNonUsInvestor">
              Non-US investors, please check this box
            </p>
          </div>
          <div
            class="mt-2 border-[1px] bg-[#3982AF]/10 p-2 text-sm text-[#3982AF] ring-[#3982AF]/20"
            v-if="is_non_us_investor"
          >
            Please complete the
            <a href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf" target="_blank" class="hyperlink !font-semibold"
              >W-8BEN form</a
            >
            and upload it
            <a :href="`${rails_url()}/investors/${route.params.individual_id}/files`" class="hyperlink !font-semibold"
              >here</a
            >. If you have already done this, please disregard this message.
          </div>
        </div>
        <div class="mb-5">
          <VTextField
            v-model="individual_form.fincen_id"
            :label="t('shared.FinCEN ID')"
            property="fincen_id"
            type="text"
            :v$="v$"
            :required="true"
          />
          <div class="mt-1 flex items-center gap-1 text-sm text-gray-500">
            <a
              href="https://fincenid.fincen.gov/landing"
              class="cursor-pointer font-medium text-[#51889B] underline decoration-[#51889B]/50"
              target="_blank"
            >
              Generate {{ t('shared.FinCEN ID') }} here
            </a>

            <div class="group relative hover:block">
              <div
                class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:shadow-md hover:ring-gray-700/30 active:shadow-sm"
              >
                <VIcon name="help" class="h-5 w-5 text-gray-700" />
              </div>
              <div class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block">
                <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
                  A FinCEN ID is a unique identifying number issued to an individual by FinCEN. Your FinCEN ID will be
                  used for Beneficial Ownership Information Reporting and eliminates the need for us to require
                  submission of a federal form of identification.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VSection>
    <VSection class="flex gap-3" id="disbursement-preferences">
      <div class="w-1/2">
        <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
          Disbursement Preferences
          <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">3/3</span>
        </h3>
      </div>
      <div class="w-1/2">
        <DisbursementPreferencesForm
          v-model="individual_form"
          :v$="v$"
          :check_bank_account_number="check_bank_account_number"
        />
        <div class="mt-24">
          <VButton variant="v-blue" size="lg" :click="submit">Confirm Changes</VButton>
        </div>
      </div>
    </VSection>
  </TheLayout>
</template>
