<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { computed, ref } from 'vue'
import { VButton, VIcon, VTextField } from '@/modules/shared/components'

const { n } = useExtendedI18n()
const props = defineProps<{
  disbursement_transfers: any[]
  is_disbursement_new: boolean
  aggregateValues: any
  toggleAllInvestor: (checked: boolean) => void
  toggleInvestor: (transfer: any, is_active: boolean) => void
  onChangeTransfer: (transfer: any, key: string) => void
  getCarriedInterest: (transfer: any) => number
}>()

const disbursement_transfers = computed(() => props.disbursement_transfers)
const aggregateValues = computed(() => props.aggregateValues)

const columns = ref([
  { key: 'total_capital', label: 'Total Capital', visible: true },
  { key: 'capital', label: 'Return of Capital', visible: false },
  { key: 'preferred_return', label: 'Preferred Return', visible: false },
  { key: 'profit', label: 'Gross Income', visible: false },
  { key: 'carried_interest', label: 'Carried Interest', visible: true },
  { key: 'other_fees', label: 'Other Fees', visible: true },
  { key: 'total_distributed', label: 'Distributed', disabled: true, visible: true },
  { key: 'hurdle_remaining', label: 'Capital Hurdle', disabled: true, visible: true },
])

const visibleColumns = computed(() => columns.value.filter((column) => column.visible))

const collapsed = ref(true)
const toggleTotalCapitalBreakdown = () => {
  collapsed.value = !collapsed.value
  ;['capital', 'preferred_return', 'profit'].forEach((key) => {
    const column = columns.value.find((column) => column.key === key)
    column.visible = !collapsed.value
  })
}
</script>

<template>
  <div class="relative rounded-lg border border-gray-200">
    <table class="rounded-lg">
      <thead v-if="!collapsed">
        <tr>
          <th colspan="2"></th>
          <th colspan="4">
            <div class="flex items-center justify-center space-x-2">
              <div>Capital Breakdown</div>
              <VButton @click="toggleTotalCapitalBreakdown" size="xs-narrow"
                ><VIcon name="chevron_collapse_horizontal" class="block h-3 w-3"
              /></VButton>
            </div>
          </th>
          <th colspan="4"></th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            <input
              class="inverse relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
              type="checkbox"
              @change="toggleAllInvestor($event.target['checked'])"
              checked
              :disabled="!is_disbursement_new"
            />
          </th>
          <th class="text-left">Investor</th>
          <th class="text-right" v-for="column in visibleColumns">
            <div class="flex items-center justify-end space-x-2">
              <div class="inline-block">{{ column.label }}</div>
              <VButton
                v-if="column.key === 'total_capital' && collapsed"
                @click="toggleTotalCapitalBreakdown"
                size="xs-narrow"
                ><VIcon name="chevron_expand_horizontal" class="block h-3 w-3"
              /></VButton>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{ disabledRow: !transfer.is_active }" v-for="(transfer, i) in disbursement_transfers">
          <td class="px-3" :class="!transfer.is_active ? 'bg-gray-50' : ''">
            <input
              @change="toggleInvestor(transfer, $event.target['checked'])"
              type="checkbox"
              :checked="transfer.is_active"
              class="inverse relative block h-4 w-4 rounded border border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
              :disabled="!is_disbursement_new"
            />
          </td>
          <td class="border-r-[1px] px-2 py-2" :class="!transfer.is_active ? 'bg-gray-50' : ''">
            {{ transfer.name }}
          </td>
          <td v-for="column in visibleColumns">
            <VTextField
              v-if="!['carried_interest', 'interest_carried_interest'].includes(column.key)"
              v-model="transfer[column.key]"
              :disabled="!transfer.is_active || !!column.disabled"
              :inputClass="`!border-none !shadow-none !mt-0 text-sm !rounded-none text-right ${transfer.is_active ? 'bg-white' : ''}`"
              type="currency"
              :onInput="(e) => onChangeTransfer(transfer, column.key)"
            />
            <div v-else class="relative" :class="transfer.is_active ? 'bg-white' : ''">
              <div class="absolute left-2 top-2 rounded bg-gray-200 p-1 py-0.5 text-xs text-gray-500">
                {{ n(getCarriedInterest(transfer), 'percent') }}
              </div>
              <VTextField
                v-model="transfer[column.key]"
                :onChange="(e) => onChangeTransfer(transfer, column.key)"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right"
                :disabled="!transfer.is_active"
                type="currency"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td v-for="column in visibleColumns">
            <VTextField
              v-model="aggregateValues[column.key]"
              type="currency"
              :disabled="true"
              inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right bg-white"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<style scoped>
table {
  @apply w-full border-collapse border-spacing-0 overflow-x-auto;
}

thead {
  @apply text-[12px] text-xs uppercase text-gray-700;
}

th {
  @apply sticky top-[71px] min-w-[100px] whitespace-nowrap border-x border-b border-gray-200   px-3 py-3 sm:min-w-[10px];
}

th:first-child {
  @apply rounded-tl-lg;
}

th:last-child {
  @apply rounded-tr-lg;
}

@media (min-width: 640px) {
  th.fixed-column {
    @apply first:sticky first:left-0 first:z-10 first:border-r;
  }

  td.fixed-column {
    @apply first:sticky first:left-0 first:z-[1] first:min-w-[320px] first:max-w-[480px] first:border-r first:bg-white;
  }

  tfoot td.fixed-column {
    @apply rounded-bl-lg;
  }
}

tbody {
  @apply text-sm text-gray-700;
}

tbody td {
  @apply whitespace-nowrap border border-gray-200;
}

th:first-child,
td:first-child {
  @apply border-l-0;
}

th:last-child,
td:last-child {
  @apply border-r-0;
}

tfoot {
  @apply text-sm font-medium uppercase tracking-wide text-gray-900;
}

tfoot td {
  @apply whitespace-nowrap border-x border-gray-200;
}

table tbody td,
table tfoot td {
  border-top: none !important;
}
</style>
