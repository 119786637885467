<script setup lang="ts">
import ArrowNarrowLeftIcon from './icons/arrow-narrow-left.vue'
import ArrowNarrowRightIcon from './icons/arrow-narrow-right.vue'
import ArrowNarrowUpIcon from './icons/arrow-narrow-up.vue'
import ArrowRightWithBar from './icons/arrow-right-with-bar.vue'
import BurgerMenuIcon from './icons/burger-menu.vue'
import CameraIcon from './icons/camera.vue'
import CheckIcon from './icons/check.vue'
import ChevronDownIcon from './icons/chevron-down.vue'
import ChevronCollapseHorizontalIcon from './icons/chevron-collapse-horizontal.vue'
import ChevronExpandHorizontalIcon from './icons/chevron-expand-horizontal.vue'
import ChevronSelectorVerticalIcon from './icons/chevron-selector-vertical.vue'
import CornerRightDownIcon from './icons/corner-right-down.vue'
import CornerUpRightIcon from './icons/corner-up-right.vue'
import CsvFileIcon from './icons/csv-file.vue'
import DollarCircledIcon from './icons/dollar-circled.vue'
import EyeOffIcon from './icons/eye-off.vue'
import EyeIcon from './icons/eye.vue'
import Flag02Icon from './icons/flag-02.vue'
import Glasses02Icon from './icons/glasses-02.vue'
import HelpIcon from './icons/help.vue'
import HomeIcon from './icons/home-02.vue'
import Hourglass01Icon from './icons/hourglass-01.vue'
import LinkExternalIcon from './icons/link-external.vue'
import LoadingIcon from './icons/loading.vue'
import MoveIcon from './icons/move.vue'
import NotificationBellIcon from './icons/notification-bell.vue'
import PencilIcon from './icons/pencil.vue'
import PlusIcon from './icons/plus.vue'
import QuestionCircleFilledIcon from './icons/question-circle-filled.vue'
import ResetIcon from './icons/reset.vue'
import SettingsIcon from './icons/settings.vue'
import ShieldTickIcon from './icons/shield-tick.vue'
import SlashCircle01Icon from './icons/slash-circle-01.vue'
import XIcon from './icons/x.vue'

withDefaults(
  defineProps<{
    class?: string | string[]
    name: string
  }>(),
  {
    class: 'h-4 w-4 block',
    name: null,
  },
)

// prettier-ignore
const icons = {
  'arrow_narrow_left': ArrowNarrowLeftIcon,
  'arrow_narrow_right': ArrowNarrowRightIcon,
  'arrow_narrow_up': ArrowNarrowUpIcon,
  'arrow_right_with_bar': ArrowRightWithBar,
  'burger-menu': BurgerMenuIcon,
  'camera': CameraIcon,
  'check': CheckIcon,
  'chevron_down': ChevronDownIcon,
  'chevron_collapse_horizontal': ChevronCollapseHorizontalIcon,
  'chevron_expand_horizontal': ChevronExpandHorizontalIcon,
  'chevron_selector_vertical': ChevronSelectorVerticalIcon,
  'corner-right-down': CornerRightDownIcon,
  'corner-up-right': CornerUpRightIcon,
  'csv-file': CsvFileIcon,
  'dollar-circled': DollarCircledIcon,
  'eye-off': EyeOffIcon,
  'eye': EyeIcon,
  'flag-02': Flag02Icon,
  'glasses-02': Glasses02Icon,
  'help': HelpIcon,
  'home': HomeIcon,
  'hourglass-01': Hourglass01Icon,
  'link-external': LinkExternalIcon,
  'loading': LoadingIcon,
  'move': MoveIcon,
  'notification-bell': NotificationBellIcon,
  'pencil': PencilIcon,
  'plus': PlusIcon,
  'question': QuestionCircleFilledIcon,
  'reset': ResetIcon,
  'settings': SettingsIcon,
  'shield-tick': ShieldTickIcon,
  'slash-circle-01': SlashCircle01Icon,
  'x': XIcon,
}
</script>

<template>
  <component :is="icons[name]" v-bind="$attrs" :class="class" />
</template>
