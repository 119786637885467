<script setup lang="ts">
import { capitalize, startCase } from 'lodash'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { rails_url } from '@/modules/shared/utils/rails'
import { VTabList } from '@/modules/shared/components'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import { useOutstandingStore } from '@/modules/dashboard/stores/outstanding-store'

const { t } = useI18n()
const route = useRoute()

const id = route.params.individual_id
const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
const outstandingStore = useOutstandingStore()

const individualURL = computed(() => `${rails_url()}/investors/${id}`)
const isCurrent = (name: string) => route.name === name

const outstanding = computed(() => outstandingStore.outstanding)

onMounted(async () => {
  await Promise.all([workspaceStore.fetchCurrent(), outstandingStore.fetchOutstandings(`individual:${id}`)])
})
</script>

<template>
  <div class="mb-12 px-4 sm:px-0">
    <VTabList
      :tabs="[
        {
          current: isCurrent('investing.individual-overview'),
          name: capitalize(t('shared.overview')),
          to: {
            name: 'investing.individual-overview',
            params: {
              individual_id: id,
            },
          },
        },
        {
          current: isCurrent('investing.individual.portfolio'),
          name: capitalize(t('shared.portfolio', 0)),
          to: {
            name: 'investing.individual.portfolio',
            params: {
              individual_id: id,
            },
          },
        },
        {
          current: isCurrent('investing.individual-invoices'),
          name: capitalize(t('shared.invoice', 0)),
          to: {
            name: 'investing.individual-invoices',
            params: {
              individual_id: id,
            },
          },
          count: outstanding.invoices_count,
        },
        {
          current: isCurrent('investing.individual.documents'),
          name: capitalize(t('shared.document', 0)),
          to: {
            name: 'investing.individual.documents',
            params: {
              individual_id: id,
            },
          },
          count: outstanding.documents_count,
        },
        {
          current: isCurrent('investing.individual-files'),
          name: capitalize(t('shared.file', 0)),
          to: `${individualURL}/files`,
        },
        {
          current: isCurrent('investing.individual-notes'),
          name: capitalize(t('shared.note', 0)),
          visible: authStore.is_site_or_group_admin,
          to: `${individualURL}/notes`,
        },
        {
          current: isCurrent('investing.individual.taxes'),
          name: capitalize(t('shared.tax', 0)),
          visible: workspaceStore.current?.has_managed_entities,
          to: { name: 'investing.individual.taxes', query: { year: '2023' } },
        },
        {
          current: isCurrent('investing.individual.account'),
          name: capitalize(t('shared.account')),
          to: {
            name: 'investing.individual.account',
            params: {
              individual_id: id,
            },
          },
        },
      ]"
    />
  </div>
</template>
