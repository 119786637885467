<script setup lang="ts">
import { VSection, VTextField, VTextArea, VButton } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import { capitalize, get } from 'lodash'
import { required } from '@vuelidate/validators'
import { ref, computed, onMounted, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { Disbursement } from '../stores/disbursement-store'
import { toNumber, initialMoney } from '@/modules/shared/utils/money'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'
import { getDisbursementType, useEuropeanDisbursement } from '../utils/use-european-disbursement'
import { scrollToError } from '@/modules/shared/utils/form'
import VSelect from './v-select.vue'
import NormalDistributionTableForm from './normal-distribution-table-form.vue'
import PortfolioInterestDistributionTableForm from './portfolio-interest-distribution-table-form.vue'

////////////////////////////////////////
///// INITIALIZATION FUNCTIONS
////////////////////////////////////////
const transformDisbursement = () => {
  return {
    ...props.disbursement,
    date: props.disbursement.date ? format(new Date(props.disbursement.date), 'yyyy-MM-dd') : null,
    disbursement_transfers: props.disbursement.disbursement_transfers
      .map((transfer: any) => europeanDisbursement.transformTransfer(transfer))
      .sort((a, b) => a.name.localeCompare(b.name)),
  }
}
const initializeQuestions = () => {
  const props_config = props.disbursement.config
  allocateCarriedInterest.value = props_config.allocate_carried_interest
}
const intializedMacros = () => {
  macros.value.total_capital = props.disbursement.entered_capital_amount
  macros.value.interest_earned = props.disbursement.entered_interest_earned_amount
  macros.value.carried_interest = props.disbursement.entered_carried_interest_amount
  macros.value.other_fees = props.disbursement.entered_other_fees_amount
}
////////////////////////////////////////

const tableComponents = {
  interest: PortfolioInterestDistributionTableForm,
  normal: NormalDistributionTableForm,
}

////////////////////////////////////////

const { t } = useExtendedI18n()
const route = useRoute()
////////////////////////////////////////
const props = defineProps<{
  disbursement: Disbursement
}>()

const emit = defineEmits<{
  (e: 'submit', payload: any): void
}>()

////////////////////////////////////////
///// INITIAL STATES
////////////////////////////////////////
const allocateCarriedInterest = ref(false)
const disbursementForm = ref(null)
const macros = ref({
  total_capital: null,
  interest_earned: null,
  carried_interest: null,
  other_fees: null,
})
////////////////////////////////////////

////////////////////////////////////////
///// UTILS?
////////////////////////////////////////
const is_disbursement_new = computed(() => !props.disbursement.id)
const config = computed(() => {
  return {
    allocateCarriedInterest: allocateCarriedInterest.value,
  }
})
const europeanDisbursement = useEuropeanDisbursement(props.disbursement.disbursement_allocation_type, config)
////////////////////////////////////////

// FORM DATA
const rules = {
  prior: { required },
  date: { required },
  type: { required },
}
const v$ = useVuelidate(rules, disbursementForm, { $lazy: true })

const disbursement_type = computed(() => getDisbursementType(props.disbursement.disbursement_allocation_type))
const macroUpdateTransfers = (key: string) => {
  disbursementForm.value.disbursement_transfers
    .filter((transfer) => transfer.is_active)
    .forEach((transfer) => (transfer = europeanDisbursement.macrosUpdateTransfer(transfer, macros.value, key)))
}
const aggregateValues = computed(() =>
  europeanDisbursement.calculateAggregate(disbursementForm.value.disbursement_transfers),
)

const submit = async () => {
  const valid = await v$.value.$validate()
  if (!valid) {
    scrollToError(v$, Object.keys(rules))
    return
  }
  const payload = {
    id: disbursementForm.value.id,
    investor_set_id: route.params.entity_id,
    portfolio_disbursement_allocation_id: props.disbursement.portfolio_disbursement_allocation_id,
    capital_amount: toNumber(aggregateValues.value.total_capital),
    currency: disbursementForm.value.currency,
    date: disbursementForm.value.date,
    notes: disbursementForm.value.notes,
    prior: `${disbursementForm.value.prior}`,
    fee_percentage: 0,
    fee_percentage_of_commitment: 'false',
    other_fee_amount: toNumber(aggregateValues.value.other_fees),
    include_fees: '1',
    is_recallable: disbursementForm.value.is_recallable,
    itemize_fee: true,
    entered_capital_amount: macros.value.total_capital ? toNumber(macros.value.total_capital) : null,
    entered_management_fees_amount: macros.value.carried_interest,
    entered_other_fees_amount: macros.value.other_fees ? toNumber(macros.value.other_fees) : null,
    selected_capital_type: null,
    other_fee_type: 'pro-rata',
    type: disbursementForm.value.type,
    allocate_carried_interest: allocateCarriedInterest.value,
    disbursement_transfers_attributes: [],
  }
  // get the active transfers
  payload.disbursement_transfers_attributes = disbursementForm.value.disbursement_transfers
    .filter((transfer) => transfer.is_active)
    .map((transfer) => {
      let return_of_capital = transfer.capital

      if (disbursement_type.value === 'interest') {
        return_of_capital = transfer.principal
      }

      return {
        id: transfer.id,
        investor_set_commitment_id: transfer.investor_set_commitment_id,
        currency: disbursementForm.value.currency,
        date: null,
        prior: false,
        capital_amount: toNumber(transfer.total_capital || initialMoney),
        return_of_capital_amount: toNumber(return_of_capital || initialMoney),
        preferred_return_amount: toNumber(transfer.preferred_return || initialMoney),
        profit_amount: toNumber(transfer.profit || initialMoney),
        interest_earned_amount: toNumber(transfer.interest_earned || initialMoney),
        fee_amount: toNumber(transfer.carried_interest || initialMoney),
        other_fee_amount: toNumber(transfer.other_fees || initialMoney),
      }
    })
  emit('submit', payload)
}
// Pass the submit function back to the parent
defineExpose({
  submit,
})

////////////////////////////////////////
///// TABLE FUNCTIONS
////////////////////////////////////////
const toggleAllInvestor = (checked) => {
  if (!is_disbursement_new.value) return
  disbursementForm.value.disbursement_transfers.forEach((transfer) => {
    toggleInvestor(transfer, checked)
  })
}
const toggleInvestor = (transfer, is_active) => {
  transfer.is_active = is_active
  if (transfer.is_active) {
    europeanDisbursement.macrosUpdateTransfer(transfer, macros.value, 'total_capital')
    europeanDisbursement.macrosUpdateTransfer(transfer, macros.value, 'interest_earned')
    europeanDisbursement.macrosUpdateTransfer(transfer, macros.value, 'carried_interest')
    europeanDisbursement.macrosUpdateTransfer(transfer, macros.value, 'other_fees')
  } else {
    transfer.total_capital = initialMoney
    transfer.capital = initialMoney
    transfer.interest_earned = initialMoney
    transfer.profit = initialMoney
    transfer.carried_interest = initialMoney
    transfer.other_fees = initialMoney
    europeanDisbursement.updateTransfer(transfer, 'total_capital')
  }
}
const onChangeTransfer = (transfer, key) => {
  transfer = europeanDisbursement.updateTransfer(transfer, key)
}
const getCarriedInterest = (transfer) => {
  if (macros.value?.carried_interest) return macros.value.carried_interest / 100
  return transfer.carried_interest_percentage
}
////////////////////////////////////////

onMounted(async () => {
  initializeQuestions()
  disbursementForm.value = transformDisbursement()
  intializedMacros()

  if (is_disbursement_new.value && props.disbursement.portfolio_disbursement_allocation_id) {
    macroUpdateTransfers('interest_earned')
    macroUpdateTransfers('total_capital')
    macroUpdateTransfers('carried_interest')
    macroUpdateTransfers('other_fees')
  }
  watch(
    () => allocateCarriedInterest.value,
    (v) => {
      macros.value.carried_interest = null
      macroUpdateTransfers('carried_interest')
    },
  )
})
</script>

<template>
  <VSection class="mt-12 space-y-7 px-52" id="general-information" v-if="disbursementForm">
    <div class="flex gap-3">
      <div class="w-1/2">
        <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
          {{ capitalize(t('shared.general information')) }}
          <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">1/2</span>
        </h3>
      </div>
      <div class="mt-6 w-1/2 space-y-7">
        <VTextField
          v-model="disbursementForm.date"
          :label="capitalize(t('shared.distribution date'))"
          type="date"
          property="date"
          :v$="v$"
        />
        <div class="my-12 h-1 bg-gray-100"></div>
        <div v-if="is_disbursement_new">
          <label for="x1" class="block text-sm font-medium leading-6 text-gray-900"
            >Would you like to send a notification to investors?</label
          >
          <select
            id="x1"
            name="x1"
            v-model="disbursementForm.prior"
            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
          >
            <option :value="false">Yes, send notifications to investors</option>
            <option :value="true">No, only record a distribution</option>
          </select>
        </div>
        <VTextArea
          v-model="disbursementForm.notes"
          :label="capitalize(t('shared.email content'))"
          property="notes"
          :v$="v$"
          description="This section is included in the body of the email sent. Keep it brief. URLs are hyperlinked."
          v-if="!disbursementForm.prior"
        />
        <div v-if="is_disbursement_new">
          <label for="x1" class="block text-sm font-medium leading-6 text-gray-900">Are these funds recallable?</label>
          <select
            id="x1"
            name="x1"
            v-model="disbursementForm.is_recallable"
            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
          >
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
        <div class="">
          <VSelect
            v-model="disbursementForm.type"
            :label="capitalize(t('shared.type'))"
            :options="[
              { label: 'Cash', value: 'cash' },
              { label: 'Asset', value: 'asset' },
              { label: 'Recycled Funds', value: 'recycled' },
            ]"
            property="type"
            :v$="v$"
          />
          <div class="mt-2 text-sm text-gray-500" v-if="disbursementForm.type === 'recycled'">
            These funds are being held back by the fund to be used in future investments
          </div>
        </div>
        <div class="my-12 h-1 bg-gray-100"></div>
        <VTextField
          v-model="macros.total_capital"
          type="currency"
          :label="capitalize(t('shared.total capital'))"
          :onInput="() => macroUpdateTransfers('total_capital')"
          description="Allocated based on ownership"
        />
        <div>
          <!-- Step 2: Would you like to include management fees? -->
          <label for="x2" class="block text-sm font-medium leading-6 text-gray-900"
            >How would you like to allocate carried interest?</label
          >
          <select
            id="x2"
            name="x2"
            v-model="allocateCarriedInterest"
            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
          >
            <option :value="false">Use the default fee % as shown below</option>
            <option :value="true">Enter a different fee % to apply to all investors</option>
          </select>
        </div>
        <VTextField
          v-model="macros.carried_interest"
          type="percent"
          :onInput="() => macroUpdateTransfers('carried_interest')"
          placeholder="--"
          label="Enter carried interest"
          v-if="allocateCarriedInterest"
        />
        <VTextField
          v-model="macros.other_fees"
          type="currency"
          :onInput="() => macroUpdateTransfers('other_fees')"
          label="Enter any other fees"
        />
      </div>
    </div>
  </VSection>
  <VSection class="space-y-7" id="investor-allocations" v-if="disbursementForm">
    <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
      {{ capitalize(t('shared.investor allocation', 0)) }}
      <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">2/2</span>
    </h3>
    <component
      :is="tableComponents[disbursement_type]"
      :disbursement_transfers="disbursementForm.disbursement_transfers"
      :is_disbursement_new="is_disbursement_new"
      :aggregateValues="aggregateValues"
      :macros="macros"
      :toggleAllInvestor="toggleAllInvestor"
      :toggleInvestor="toggleInvestor"
      :onChangeTransfer="onChangeTransfer"
      :getCarriedInterest="getCarriedInterest"
    />
  </VSection>
</template>

<style>
#disbursement-prior .radio-container label:nth-child(1) {
  @apply rounded-t-lg border-[1px];
}

#disbursement-prior .radio-container label:nth-child(2) {
  @apply rounded-b-lg  border-b-[1px]  border-l-[1px]  border-r-[1px];
}

#disbursement-prior .radio-container label {
  @apply !m-0 cursor-pointer border-gray-300 px-5 py-3 hover:border-sky-500 hover:bg-sky-100;
}

#disbursement-prior .radio-container:hover label:nth-child(1) {
  @apply border-b-sky-500;
}
</style>
