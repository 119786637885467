<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { computed } from 'vue'
import { VTextField } from '@/modules/shared/components'

const { n } = useExtendedI18n()
const props = defineProps<{
  disbursement_transfers: any[]
  is_disbursement_new: boolean
  aggregateValues: any
  toggleAllInvestor: (checked: boolean) => void
  toggleInvestor: (transfer: any, is_active: boolean) => void
  onChangeTransfer: (transfer: any, key: string) => void
  getCarriedInterest: (transfer: any) => number
}>()

const disbursement_transfers = computed(() => props.disbursement_transfers)
const aggregateValues = computed(() => props.aggregateValues)

const columns = computed(() => [
  { key: 'total_capital', label: 'Total' },
  { key: 'principal', label: 'Principal' },
  { key: 'interest_earned', label: 'Interest' },
  { key: 'carried_interest', label: 'Carried Interest' },
  { key: 'other_fees', label: 'Other Fees' },
  { key: 'total_distributed', label: 'Total Distributed', disabled: true },
  { key: 'hurdle_remaining', label: 'Capital Hurdle Before Profits', disabled: true },
])
</script>

<template>
  <div class="relative w-full overflow-x-auto">
    <table id="capital-call-form-table" class="w-full border-separate border-spacing-0">
      <thead>
        <tr>
          <th class="!pl-3">
            <input
              class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
              type="checkbox"
              @change="toggleAllInvestor($event.target['checked'])"
              checked
              :disabled="!is_disbursement_new"
            />
          </th>
          <th class="!pl-3 text-left">Investor</th>
          <th class="pl-3 text-right" v-for="column in columns">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="border-b-[1px] border-gray-300"
          :class="{ disabledRow: !transfer.is_active }"
          v-for="(transfer, i) in disbursement_transfers"
        >
          <td class="pl-3">
            <input
              type="checkbox"
              class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
              :checked="transfer.is_active"
              :disabled="!is_disbursement_new"
              @change="toggleInvestor(transfer, $event.target['checked'])"
            />
          </td>
          <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-base text-gray-700">
            {{ transfer.name }}
          </td>
          <td class="border-r-[1px] border-gray-300 text-base text-gray-700" v-for="column in columns">
            <VTextField
              v-model="transfer[column.key]"
              :disabled="!transfer.is_active || !!column.disabled"
              type="currency"
              :onInput="(e) => onChangeTransfer(transfer, column.key)"
              inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right"
              v-if="!['carried_interest', 'interest_carried_interest'].includes(column.key)"
            />
            <div class="flex items-center pl-3" v-else>
              <div class="rounded bg-blue-50 p-1 py-0.5 text-xs text-gray-500">
                {{ n(getCarriedInterest(transfer), 'percent') }}
              </div>
              <VTextField
                v-model="transfer[column.key]"
                :disabled="!transfer.is_active"
                type="currency"
                :onChange="(e) => onChangeTransfer(transfer, column.key)"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-b-[1px] border-gray-300">
          <td class="pl-3"></td>
          <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-xs text-gray-700">TOTALS</td>
          <td class="border-r-[1px] border-gray-300 bg-gray-50 text-base text-gray-700" v-for="column in columns">
            <VTextField
              v-model="aggregateValues[column.key]"
              type="currency"
              :disabled="true"
              inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
